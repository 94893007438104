.header{
    text-align: center;
    font: normal normal 700 30px/29px Lato;
    letter-spacing: 0px;
    font-family: 'Lato', sans-serif;
    color: #282D2A;
    opacity: 1;
}

.boxBorder{
    background: white 0% 0% no-repeat padding-box;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    min-height: 200px;
    margin-top: 50px;
    padding: 0px;
}

.boxBorder h4, h6{
    color: #5A727A;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
}

.step_1_price_summary{
    background-color: #3C4096;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
}

.step_1_price_summary_sub{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 460px;
  
}

.headerContainer{
    background-image: url(../../assets//images//header-pattern.png);
    background-size: auto auto;
    background-repeat: no-repeat;
    height: 70px;
    padding-top: 10px;
    padding-left: 20px;
}

@media screen and (max-width: 768px) {
    .boxBorder{
        padding: 0px;
        background-color: #EAECF7;
        border: none;
        height: 100vh;
        margin-top: 0px !important;
    }

    .form-container{
        background-color: #EAECF7 !important;
        padding: 0px !important;
    }

    .step_1_price_summary_sub{
        width: 300px;
    }
}
